export const checksums = {
  "pages": "v3.3.0--FpfGP65NSkR4h7xBlMqKQ99z6C5tQ4v-TkNgbn-DgmQ",
  "posts": "v3.3.0--k39_9TEaz9SVmS9nVXOwVxQDzTStfagrT-7D19FIYac",
  "moments": "v3.3.0--I657kazmRoPNrV6kyyv_YtxxphEdLeGBzUTidutsTOo"
}
export const checksumsStructure = {
  "pages": "k7UiOxP8yS-gx2dTwYwx3FI7StDFft1TByLwcVeNFfg",
  "posts": "sALYrYCc3AEdmk0Pr0Gqz6tSDAl4FnYOr-VXiNAvqHA",
  "moments": "zbS2a1-fH4df0qdyi4Wj6f3EzR-FjE7bvSarOO80dQM"
}

export const tables = {
  "pages": "_content_pages",
  "posts": "_content_posts",
  "moments": "_content_moments",
  "info": "_content_info"
}

export default {
  "pages": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "sitemap": "json",
      "hideComments": "boolean",
      "layout": "string",
      "readingTime": "json"
    }
  },
  "posts": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "sitemap": "json",
      "class": "string",
      "cover": "string",
      "coverSize": "json",
      "date": "string",
      "draft": "boolean",
      "hideComments": "boolean",
      "location": "string",
      "readingTime": "json",
      "tags": "json",
      "time": "string",
      "weather": "string"
    }
  },
  "moments": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "sitemap": "json",
      "datetime": "string",
      "hideComments": "boolean",
      "location": "string",
      "tags": "json",
      "content": "string",
      "media": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}