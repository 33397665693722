import revive_payload_client_mMm3jrWfsVPZl2OSqCS8zV2j0CFHm3iITAOkIlDCY2Y from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_W9ZDFa3B2k4Y3YRggGwJPAlVW1YZcLyAIBUqZi7pHjw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6oWZCn_fBwxv6GBYw5C3G73rJpWt_pKL2mjCSyxx290 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_060DADpdK_i_CZIJ7T_kd9NuWjBl_fs7w6KjSdg4nU4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9Pn_HjE62Pw2RQUHXvztLfmB8cusi9jR0eMNYLwS4nQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZqYM8aJLHRtCtqMnyONrJLmrLGrq_ba1_lZbn3k3rkk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9_3JKjckmx215qnP1tZQQXeUsEMPQqb00j8SpGaMGQc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_bszlejI7_1j4GNI89nAoIym4O_WThR52gysgasNQmds from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_better-sqlite3@11.9.1_db0@0.3.1__aeeef41428a89f8ab1c8a5d1baf7a0b3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_n_5xhTlKldpsNEB1dT5sKE2tZXP9_Ioo3TwFxAIBmEE from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@9.10.1_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1__e66978edc0ef3b150eee8df06639ee0c/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import colors_k28JqycG68NidzxvkofbSA4CRzHf0Fhm2ZNX1xMXYpI from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.27.0_db0@0.3.1_better-sqlite3@11.9.1__embla-carousel@8.5_8b44877dddb0fc6ae8df05dd5c3ab5aa/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_IMO6gO9ze71NBJkMOn449nSiqKw_P2sbdg__6Tqhcbg from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_lightningcs_b4f37981c51f422028353950ad85c344/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.2.0_magicast@0.3.5/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_mMm3jrWfsVPZl2OSqCS8zV2j0CFHm3iITAOkIlDCY2Y,
  unhead_W9ZDFa3B2k4Y3YRggGwJPAlVW1YZcLyAIBUqZi7pHjw,
  router_6oWZCn_fBwxv6GBYw5C3G73rJpWt_pKL2mjCSyxx290,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_060DADpdK_i_CZIJ7T_kd9NuWjBl_fs7w6KjSdg4nU4,
  navigation_repaint_client_9Pn_HjE62Pw2RQUHXvztLfmB8cusi9jR0eMNYLwS4nQ,
  check_outdated_build_client_ZqYM8aJLHRtCtqMnyONrJLmrLGrq_ba1_lZbn3k3rkk,
  chunk_reload_client_9_3JKjckmx215qnP1tZQQXeUsEMPQqb00j8SpGaMGQc,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_bszlejI7_1j4GNI89nAoIym4O_WThR52gysgasNQmds,
  sentry_client_n_5xhTlKldpsNEB1dT5sKE2tZXP9_Ioo3TwFxAIBmEE,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  plugin_client_0DSVlk_DWJIsXiY4Q2rzCCZ9XNjn36aQ0XNJc69R_bc,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  colors_k28JqycG68NidzxvkofbSA4CRzHf0Fhm2ZNX1xMXYpI,
  plugin_IMO6gO9ze71NBJkMOn449nSiqKw_P2sbdg__6Tqhcbg,
  plugin_auto_pageviews_client_jObAEHGbMiFxvkHYEDQUtr3ztxD1c5DoRbsB1cWdRVs
]